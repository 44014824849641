import React, { useState } from "react";
import TopContactForm from "../TopContact/TopContactForm";
import CircleTopHandImage from '../../../Images/CircleTophand.png';
import CircleTop from '../../../Images/CircleTop2.png';
import DimandImg from '../../../Images/etherium.gif'
import arrow from '../../../Images/top=arrow.png'

import {
  Root
} from "../../LandingPage/Navigation/Navbarstyle";

import { Container } from "../../CommonStyledComponent/Commonstyled";
import {
  TopWrapper, TopWrapperLeft, TopWrapperRight,
  TopSubHeading, TopParagraph
} from "./TopStyled";



export const Topsection = () => {
  return (
    <Root>
      <Container>
        <TopWrapper>
          <TopWrapperLeft>
            <TopParagraph>
              Accept crypto payments at <br /> unbelievably low costs with our
            </TopParagraph>
            <TopSubHeading>
              TOP-RATED <br /> CRYPTO PAYMENT <br /> GATEWAY
            </TopSubHeading>
            <div className="top-arrow">
              <img src={arrow} />
            </div>
          </TopWrapperLeft>
          <TopWrapperRight>          
              <TopContactForm />         
          </TopWrapperRight>
        </TopWrapper>
      </Container>
    </Root>
  );
};
