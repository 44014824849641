import React from 'react'
import { Container } from "../../CommonStyledComponent/Commonstyled.js";
import { ClearcodeContainer, ClearCodeHeading, ClearCodeHeadingSpan, ClearCodeParagraph, ClearCodeButton } from './ClearCodeStyled.js';
const ClearCode = () => {
    return (
        <>
            <Container>
                <ClearcodeContainer>
                    <ClearCodeHeading>
                    CLEAR <ClearCodeHeadingSpan> CODE. </ClearCodeHeadingSpan> <br/>
                    SIMPLE <ClearCodeHeadingSpan>  SETUP. </ClearCodeHeadingSpan>
                    </ClearCodeHeading>
                    <ClearCodeParagraph>
                    Connect our simple codes with your business. Manage your funds and grow your business <br/> with  our one-
                    stop payments solution.
                    </ClearCodeParagraph>
                    <ClearCodeButton> <a href='#bottom'>Get In Touch</a> </ClearCodeButton>
                </ClearcodeContainer>
            </Container>

        </>
    )
}

export default ClearCode