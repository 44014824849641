import React from 'react';
import crypto from '../../../Images/cryptocurrency.png'
import ustdIcon from '../../../Images/ustd-icon.png';
import tron from '../../../Images/tron-icon.png';
import ethereum from '../../../Images/ethereum-icon.png';
import bnb from '../../../Images/bnb-icon.png';
import fxy from '../../../Images/fxy-icon.png';
import mastic from '../../../Images/mastic-icon.png';
import usdc from '../../../Images/usdc-icon.png';

import { CryptoContainer, CryptoContent, ButtonOne, 
  ButtonTwo, ButtonThree, ButtocnFour, ButtonFive, ButtonSix, ButtonSeven,
   CryptoIcon } from './CryptoCurrencyStyled';
import { FutureHeading } from '../FutureFinance/FutureStyled';

const CryptoCurrency = () => {
  return (
    <>
    <CryptoContainer>
        <FutureHeading> ACCEPTING 20+  <br /> CRYPTOCURRENCIES</FutureHeading>
        <CryptoContent> 
          <ButtonOne>  <CryptoIcon src={ustdIcon} />  USDT   </ButtonOne>
          <ButtonTwo>  <CryptoIcon src={tron} />  Tron   </ButtonTwo>
          <ButtonThree> <CryptoIcon src={ethereum} />  Ethereum </ButtonThree>   
          <ButtocnFour> <CryptoIcon src={bnb} />  BNB </ButtocnFour>       
          <ButtonFive> <CryptoIcon src={fxy} />  FXY </ButtonFive>       
          <ButtonSix> <CryptoIcon src={mastic} />  Matic </ButtonSix>  
          <ButtonSeven> <CryptoIcon src={usdc} />  USDC </ButtonSeven>           
        </CryptoContent>
    </CryptoContainer>
    </>
  )
}

export default CryptoCurrency