import styled from "styled-components";

export const Root = styled.div`
background-color: #000;
`;
export const Container = styled.div`
width: 1250px;
margin: 0px auto;
padding: 50px 0px;
@media(max-width: 1300px){
    width: 100%;
}
`;

export const HeadingWrapper = styled.div`
width: 72%;
@media(max-width: 768px){
    width: 100%;
}
`;
export const Wrapper = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
// padding: 50px 20px;
padding: 50px 20px 30px 20px;
@media(max-width: 768px){
    padding: 50px 20px 0px 20px;
}
`;
export const BoldHeading = styled.div`
font-size: 50px;
font-weight:800;
color:#fff;
text-transform: uppercase;

@media(max-width: 1024px){
    font-size: 35px;
}
@media(max-width: 576px){
    font-size: 28px;
}
// @media(max-width: 425px){
//     font-size: 28px;
// }

`;
export const ParagraphWrapper = styled.div``;
export const Paragraph = styled.div`

color: #fff;
font-size:26px;
font-weight:300;
padding: 20px 0;
font-family: "DM Sans", sans-serif;
font-style: italic;

@media(max-width: 1024px){
    font-size: 25px;
    padding: 20px 10px;
}
@media(max-width: 576px){
    font-size: 18px;
    padding: 10px 10px;
}
`;



export const CardContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 20px;

padding: 20px 20px 50px 20px;

@media(max-width: 900px){
    grid-template-columns: 1fr 1fr;
}
@media(max-width: 500px){
    grid-template-columns: 1fr;
    padding: 20px 30px 50px 20px;
}
`;



export const Card = styled.div`
position: relative;
text-align: center;
padding: 25px 20px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
justify-content: center;
border: double 2px transparent;
border-bottom-right-radius: 80px;
background-image: linear-gradient(to right , #000000, #000000),radial-gradient(circle at bottom right, #ffffff0f,#ffffff54);
background-origin: border-box;
background-clip: padding-box, border-box;
@media(max-width: 500px){
    width: 90%;
}

`;
export const TriangleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  z-index: 1; /* Ensure the triangle is above the card content */
`;

export const TriangleOutline = styled.div`
  position: absolute;
  top:-84px;
left:-108px;
  width: 0;
  height: 0;
  border-left: 42px solid transparent;
  border-top: 42px solid transparent;
  border-bottom: 42px solid white; /* Change this color as needed */
  border-right: 42px solid white; /* Change this color as needed */
  background-image: linear-gradient(to right , #000000, #000000),radial-gradient(circle at bottom right, #ffffff0f,#ffffff54);
  background-origin: border-box;
`;
export const Triangle = styled.div`

position: absolute;
bottom: 0;
right: 0;
width: 0;
height: 0;
border-left: 42px solid transparent;
border-top: 42px solid transparent;
border-bottom: 42px solid transparent; /* Change this color as needed */
border-right: 42px solid transparent; /* Change this color as needed */
z-index: 1; /* Ensure the triangle is above the card content */

`;

export const CardImageWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;
export const CardImage = styled.img`
// width: 100%;
`;
export const CardImageHeadingWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
padding: 10px 0;
`;
export const CardImageHeading = styled.div`
color: #fff;
font-weight: 500;
font-size: 22px;
font-family: "DM Sans", sans-serif;

`;
export const CardImageParaWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
`;
export const CardImagePara = styled.div`
font-size: 16px;
font-weight:300;
font-family: "DM Sans", sans-serif;
font-style: italic;
color: #fff;
`;
