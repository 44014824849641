import React from "react";
import {
  Button,
  ButtonWrapper,
  CheckBox,
  CheckBoxTextWrapper,
  CheckBoxWrapper,
  CheckboxText,
  // Container,
  Heading,
  HeadingWrapper,
  Image,
  Imagewrapper,
  LeftContainer,
  LeftRightContainer,
  OrderListItem,
  Paragraph,
  ParagraphHeading,
  RightContainer,
  Root,
  UnOrderListWrapper,
  Wrapper,
} from "./Exchangestyle";
import CheckBoxIcon from "./../../../Images/check-square.png";
import CryptoExchangeImage from "./../../../Images/cryptoExchange.png";
import { Container } from "../../CommonStyledComponent/Commonstyled";

export const CryptoExchange = () => {
  return (
    <Root>
      <Container>
        <Wrapper>
          <LeftRightContainer>
            <LeftContainer>
              <HeadingWrapper>
                <Heading> Crypto Exchange</Heading>
              </HeadingWrapper>
              <ParagraphHeading>
                <Paragraph>
                  Unlock the future with our revolutionary platform, designed to
                  elevate your financial experience.
                </Paragraph>
              </ParagraphHeading>
              <UnOrderListWrapper>
                <OrderListItem>
                  <CheckBoxWrapper>
                    <CheckBox src={CheckBoxIcon} />
                  </CheckBoxWrapper>
                  <CheckBoxTextWrapper>
                    <CheckboxText>Instant Transaction</CheckboxText>
                  </CheckBoxTextWrapper>
                </OrderListItem>
                <OrderListItem MidExchange>
                  <CheckBoxWrapper>
                    <CheckBox src={CheckBoxIcon} />
                  </CheckBoxWrapper>
                  <CheckBoxTextWrapper>
                    <CheckboxText>Redefined Security</CheckboxText>
                  </CheckBoxTextWrapper>
                </OrderListItem>
                <OrderListItem>
                  <CheckBoxWrapper>
                    <CheckBox src={CheckBoxIcon} />
                  </CheckBoxWrapper>
                  <CheckBoxTextWrapper>
                    <CheckboxText>Diverse Crypto Support </CheckboxText>
                  </CheckBoxTextWrapper>
                </OrderListItem>
              </UnOrderListWrapper>
              <ButtonWrapper>
                <Button onClick={()=> window.open("https://www.floxypay.com/en", "_blank")}>Go to FloxyPay Exchange</Button>
              </ButtonWrapper>
            </LeftContainer>
            <RightContainer>
              <Imagewrapper>
                <Image src={CryptoExchangeImage} />
              </Imagewrapper>
            </RightContainer>
          </LeftRightContainer>
        </Wrapper>
      </Container>
    </Root>
  );
};
