import React, { useState } from "react";
import BrandLogo from "./../../../Images/FloxyBrandLogo.png";
// import CircleTop from "./../../../Images/circletop.png";
import MenuBurgerIcon from "./../../../Images/menuBurger.png";
import { NavLink, useNavigate } from "react-router-dom";


import {  
  CloseIcon,
  CloseIconWrapper, 
  Logo,
  LogoWrapper,
  MobileMenuBar,
  MobileNavbarWrapper,
  MobileView,
  MobileViewItem,
  MobileViewWrapper,
  MobileviewMenuItemWrapper,
  NavBarItem,
  NavBarItemList,
  NavBarItemWrapper,
  NavBarWrapper,
  
} from "./Navbarstyle";
import { Container } from "../../CommonStyledComponent/Commonstyled";
import styled from "styled-components";

const Root = styled.div`
  background-color: #000;

`;
export const NavBar = ({setMoveToBottom}) => {

  const [IsClick, setIsClick] = useState(false);
  const ToggleHandler = () => {
    setIsClick(!IsClick);
  };
  const CloseHandle = () => {
    setIsClick(false);
  };
  const navigate = useNavigate();
  
const handleClickContact=()=>{
  const url = window.location.href;

  // Parse the URL to extract the path
  const path = new URL(url).pathname;

  // Split the path by '/'
  const segments = path.split('/');

  // The last segment will be the parameter value
  const params = segments[segments.length - 1];
if(params=='privacypolicy'){
  navigate('/')
}

}
  return (
    <Root>
      <Container>
        <NavBarWrapper>
          <LogoWrapper>
            <Logo src={BrandLogo} onClick={()=>navigate('/')} />
          </LogoWrapper>
          <NavBarItemWrapper>
            <NavBarItem>
              <NavBarItemList> <NavLink className="navbarlink" to="paymentgateaway">Payment Gateway </NavLink>  </NavBarItemList>
              <NavBarItemList> <NavLink className="navbarlink" onClick={()=> window.open("https://www.floxypay.com/en", "_blank")}>Exchange </NavLink>  </NavBarItemList>
              <NavBarItemList> <NavLink className="navbarlink" onClick={()=> window.open("https://floxypay.io/floxyapp/floxyapp.html", "_blank")}>Wallet </NavLink>  </NavBarItemList>
              <NavBarItemList onClick={handleClickContact}>   <a href="#contactformsection" className="navbtn"> Contact Us </a>  </NavBarItemList>

            </NavBarItem>
            <MobileNavbarWrapper onClick={ToggleHandler}>
              <MobileMenuBar src={MenuBurgerIcon} />
            </MobileNavbarWrapper>
          </NavBarItemWrapper>
        </NavBarWrapper>

        {IsClick && (
          <MobileViewWrapper>
            <MobileView>
              <CloseIconWrapper onClick={CloseHandle}>
                <CloseIcon> X </CloseIcon>
              </CloseIconWrapper>
              <MobileviewMenuItemWrapper>
                <MobileViewItem onClick={()=>{CloseHandle();navigate('/paymentgateaway')}}> Payment Gateway  </MobileViewItem>
                <MobileViewItem onClick={()=> {window.open("https://floxypay.io/floxyapp/floxyapp.html", "_blank");CloseHandle()}}>
                  Wallet
                </MobileViewItem>
                <MobileViewItem onClick={()=> {window.open("https://www.floxypay.com/en", "_blank");CloseHandle()}}>Exchange</MobileViewItem>
              </MobileviewMenuItemWrapper>
            </MobileView>
          </MobileViewWrapper>
        )}        
      </Container>
    </Root>
  );
};
