import React from "react";
import { Container, CopyWriteContainer, CopyWriteText, MenuOrdererdList, MenuOrdererdListWrapper, Root, Span,
  SocialMediaLinkWrapper, SocialMediaLink, 
  Certificatewrapper} from "./FooterStyle";

import FacebookIcon from '../../../Images/FacebookIcon.png';
import TwitterIcon from '../../../Images/TwitterIcon.png';
import LinkedInIcon from '../../../Images/LinkedInIcon.png'
import InstagramIcon from '../../../Images/InstagramIcon.png'
import { useNavigate } from 'react-router-dom';

const frontUrl = process.env.REACT_APP_FRONT_URL;




export const Footer = () => {
  const navigate = useNavigate()

  const openPDF = (pdfPath) => {
    window.open(pdfPath, '_blank');
  };
  
  return (
    <Root>
      <Container>
        <SocialMediaLinkWrapper>
          <a href="https://web.facebook.com/Floxypay" target="_blank">
            <SocialMediaLink src={FacebookIcon} />
          </a>

          <a href="https://twitter.com/floxy_pay" target="_blank">
            <SocialMediaLink src={TwitterIcon} />
          </a>

          <a href="https://www.instagram.com/floxypayofficial/" target="_blank">
            <SocialMediaLink src={InstagramIcon} />
          </a>

          <a href="https://www.linkedin.com/company/floxypay/" target="_blank">
            <SocialMediaLink src={LinkedInIcon} />
          </a>
        </SocialMediaLinkWrapper>

        <Certificatewrapper>
            DIGITRADEX SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
            VASPS Certificate Number: 2401-CKRDST.4225.566.2023
            D-U-N-S = 989599672
        </Certificatewrapper>
        <MenuOrdererdListWrapper>
          {/* <MenuOrdererdList>Terms & Conditions</MenuOrdererdList> */}
          <MenuOrdererdList onClick={()=>navigate('/privacypolicy')}>Privacy Policy</MenuOrdererdList>
          {/* <MenuOrdererdList>Legal</MenuOrdererdList> */}
          <MenuOrdererdList onClick={()=> window.open("https://drive.google.com/file/d/1Obg2rPZ6V6NvpZjN5-QPYLKmIavDtm18/view", "_blank")}>MSB license</MenuOrdererdList>
          <MenuOrdererdList onClick={()=> window.open(`${frontUrl}vasps.pdf`)}>VASPS License</MenuOrdererdList>
        </MenuOrdererdListWrapper>
      </Container>
      <CopyWriteContainer>
        {/* <CopyWriteText>Copyrights © 2024. All rights reserved  <Span> Powered by DIGITRADEX SP Z O O, Poland</Span></CopyWriteText> */}
        <CopyWriteText>Copyrights © 2024. All rights reserved.  <Span> powered by Tradex, poland</Span></CopyWriteText>
      </CopyWriteContainer>
    </Root>
  );
};
