import React, { useEffect, useRef }  from 'react';
import { Topsection } from '../../Component/InnerPage/Topsection/Topsection';
import FutureFinance from '../../Component/InnerPage/FutureFinance/FutureFinance';
import PayMode from '../../Component/InnerPage/PayMode/PayMode';
import ClearCode from '../../Component/InnerPage/ClearCode/ClearCode';
import CryptoCurrency from '../../Component/InnerPage/CryptoCurrency/CryptoCurrency';
import BottomContact from '../../Component/InnerPage/BottomContact/BottomContact';
import styled from 'styled-components';
import BackgroundImage from '../../Images/PaymentBg.png';

const Root = styled.div`
  background-color: #000;
  background-image: url(${BackgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
`;

const Exchange = ({data,setMoveToBottom}) => {
  const contactFormRef = useRef(null);

  useEffect(() => {
    if(data){
      if(contactFormRef != null){
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
        setMoveToBottom(false)
      }
     
    }
  }, [data])

  return (
    <Root>
      <Topsection />
      <CryptoCurrency />
      <FutureFinance />
      <PayMode />
      <ClearCode />
      <BottomContact/>
    </Root>
  )
}

export default Exchange