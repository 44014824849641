import React, { useState } from "react";
import pressGlobalnewsImage from "./../../../Images/pressGlobalnews.png";
import PressAmbImage from "./../../../Images/PressAmb.png";
import pressyahooImage from "./../../../Images/pressyahoo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import CoinMakerImage from "./../../../Images/PressCoinMaker.png";
// import AmbCryptoImage from "./../../../Images/PressAmb.png";
import axios from "axios";

import {
  BrandContainerWrapper,
  BrandLink,
  BrandName,
  BrandNameWrapper,
  // Container,
  FormEmailInput,
  FormHeading,
  FormHeadingWrapper,
  FormInputText,
  FormInputWrapper,
  FormNameInput,
  FormParagraphText,
  FormParagraphWrapper,
  FormPhoneInput,
  FormTextWrapper,
  FormWrapperContainer,
  Heading,
  HeadingWrapper,
  Paragraph,
  ParagraphWrapper,
  PressHeadingText,
  PressHeadingWrapper,
  PressParagraph,
  PressParagraphWrapper,
  Root,
  SubmitButton,
  SubmitContainer,
  SubmitWrapper,
  Wrapper,
} from "./ContactFormStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container } from "../../CommonStyledComponent/Commonstyled";
import Loader from "../../Loader/Loader";
import { environmentVariables } from "../../../config/env.config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState()
  const [mobile, setMobile] = useState()
  const [error, setError] = useState("")
  const BrandIcon = [
    { Icon: pressGlobalnewsImage, url:"https://www.globenewswire.com/en/news-release/2023/11/03/2773420/0/en/Discover-the-Floxypay-FXY-Listing-on-XT-COM.html" },
    { Icon: PressAmbImage , url:"https://ambcrypto.com/fxy-token-offering-seamless-crypto-solutions-on-the-floxypay-platform/"},
    { Icon: pressyahooImage, url:"https://finance.yahoo.com/news/floxypay-introduces-fxy-token-payment-152800917.html?guccounter=1" },
    // { Icon: CoinMakerImage },
    // { Icon: AmbCryptoImage },
  ];
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const formik = useFormik({
    initialValues:{
      name:"",
      email:"",
      // phoneNumber:"",
    },
    validationSchema: Yup.object({
      name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required("Name is required").min(2,"Required at least 3 char").max(45,"Required maximum 45 char"),
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      // phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      // .min(10, "Number is too short")
      // .max(12, "Number too long"),
    }),
    
    onSubmit:(values)=>{
      console.log("values",values)
      if(!mobile){
        setError("Number is required")
      }else{
        setLoading(true)
        let data = {
          email: values?.email,
          name: values?.name,
          number: mobile,
          countryCode : country
        };
  
        let config = {
          method: 'post',
          url: `${environmentVariables?.apiUrl}api/user/postuserdata`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
  
        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setLoading(false)
  
          toast.success(response?.data?.message, {
            autoClose: 2000,
          });
          window.history.pushState({}, '', '?thankyou');
        })
        .catch((error) => {
          setLoading(false)
  
          console.log(error?.response?.data?.message || error?.message);
          toast.error(error?.response?.data?.message || error?.message, {
            autoClose: 20000,
          });
        });
      }

    }
  });

  const { handleSubmit } = formik;

  return (
    <Root id="contactformsection">
      <Container>
        <Wrapper>
          <HeadingWrapper>
            <Heading>
              You're just a step away from leveraging Floxypay's services!
            </Heading>
          </HeadingWrapper>
          <ParagraphWrapper>
            <Paragraph>
              Seeking tailored solutions for your business requirements? Simply
              furnish us with your details, and benefit from a comprehensive
              analysis conducted by our proficient sales team.
            </Paragraph>
          </ParagraphWrapper>

          <FormWrapperContainer onSubmit={formik.handleSubmit}>
            <FormHeadingWrapper>
              <FormHeading> Get In Touch </FormHeading>
            </FormHeadingWrapper>
            <FormParagraphWrapper>
              <FormParagraphText>
                {" "}
                Get response within 24 hours{" "}
              </FormParagraphText>
            </FormParagraphWrapper>

            <FormInputWrapper>
              <FormTextWrapper>
                <FormNameInput type="text" placeholder="Name" {...formik.getFieldProps("name")}/>
                {formik.touched.name && formik.errors.name && (
                  <div style={{ color: '#ff3333', fontSize:"12px", textAlign:"start", padding:"5px 0px 0 30px" }}>{formik.errors.name}</div>
                )}
              </FormTextWrapper>
              <FormTextWrapper EmailInputSpacingManage>
                <FormEmailInput type="text" placeholder="Email"  {...formik.getFieldProps("email")}/>
                {formik.touched.email && formik.errors.email && (
                  <div style={{ color: '#ff3333', fontSize:"12px", textAlign:"start", padding:"5px 0px 0 30px" }}>{formik.errors.email}</div>
                )}
              </FormTextWrapper>

              <PhoneInput
              style={{width:"90%" , margin: "0 auto"}}
              country={"in"}
              value={mobile}
              onChange={(value, country) => {
                console.log(value, country)
                if (country && country?.dialCode) {
                  setCountry(country.countryCode.toUpperCase());
                  const originalNumber = value.slice(country.dialCode.length);
                  console.log(`+${country.dialCode}-${originalNumber}`);
                  setMobile(`${country.dialCode}-${originalNumber}`);
                } else {
                  setMobile(value);
                }
              }}
              className="logform-input"
            />
            {
              error && <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{error}</div>
            }
              {/* <FormTextWrapper>
                <FormPhoneInput type="text" placeholder="Phone Number" {...formik.getFieldProps("phoneNumber")}/>
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div style={{ color: '#ff3333', fontSize:"12px", textAlign:"start", padding:"5px 0px 0 30px" }}>{formik.errors.phoneNumber}</div>
                )}
              </FormTextWrapper> */}

              <SubmitContainer>
                <SubmitWrapper>
                  <SubmitButton onClick={handleSubmit}>{loading ? <Loader size={30} /> : "Submit"}</SubmitButton>
                </SubmitWrapper>
              </SubmitContainer>
            </FormInputWrapper>
          </FormWrapperContainer>
        </Wrapper>

        <Wrapper PressWrapper>
          <PressHeadingWrapper>
            <PressHeadingText>Press Release</PressHeadingText>
          </PressHeadingWrapper>
          <PressParagraphWrapper>
            <PressParagraph>
              FloxyPay is making headlines around the world.
            </PressParagraph>
          </PressParagraphWrapper>

          <BrandContainerWrapper>
            {BrandIcon?.map((BrandIcondata, index) => (
              <BrandNameWrapper key={index}>
                  <BrandLink href={BrandIcondata?.url} target="_blank" >
                <BrandName src={BrandIcondata?.Icon} />
                </BrandLink>
              </BrandNameWrapper>
            ))}
          </BrandContainerWrapper>
        </Wrapper>
      </Container>
      <ToastContainer/>
    </Root>
  );
};

export default ContactForm;
