import styled, { keyframes } from "styled-components";
import BackgroundImage from "./../../../Images/PaymentBg.png";

export const Root = styled.div`
  background: transparent;
  width: 100%;
  padding-bottom:  3rem;
  @media (max-width:576px){
    padding-bottom:0;
  }
`;
export const Container = styled.div`
  width: 1215px;
  margin: 0 auto;
@media(max-width: 1300px){
  width: 100%;
}

`;
export const LogoWrapper = styled.div`
@media(max-width: 1300px){
  padding: 0 20px;
}
`;
export const NavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 15px 0;
`;
export const Logo = styled.img`
  width: 100%;
  cursor: pointer;
`;
export const NavBarItemWrapper = styled.div`
padding: 0 20px;
`;
export const NavBarItem = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media(max-width: 768px){
    display:none;
  }
`;
export const NavBarItemList = styled.li`
  font-size: 18px;
  color: #fff;
  padding: 0 20px;
  list-style-type: none;
  cursor: pointer;
`;

export const HandCircleWrapper = styled.div`
width: 35%;
position: absolute;
z-index: 9;
right: 0px;
top: 195px;

@media(max-width: 1300px){
  width: 41%;
  top: 275px;
}
@media(max-width: 768px){
  width: 60%;
  top: 300px;
}
@media(max-width: 425px){
  width: 80%;
  top: 300px;
}
`;
export const HandCircle = styled.img`
width: 100%;

`;

export const LeftRightWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: flex-start;
@media(max-width: 768px){
  display: flex;
  flex-direction: column-reverse;
}
`;
export const LeftWrapper = styled.div`
  width: 50%;

  @media(max-width: 1300px){
    // padding-left: 20px;
  }
  @media(max-width: 768px){
    width: 100%;
    padding-top: 20px;
  }
`;
export const PremiumPaymentContainer = styled.div`
padding: 0 20px;
`;
export const HeadingBoldWrapper = styled.h1`
  position: relative;
  z-index: 999;
  display:flex;

  @media(max-width: 1100px){
    display:flex;
    flex-direction: column;
  }
`;
export const HeaadingBold = styled.div`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  // position: relative;
  // z-index: 99;

  @media(max-width:1100px){
    font-size: 55px;
  }
 
`;

export const HeaadingBold2 = styled.div`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  position: relative;
  z-index: 99;
  padding-left:15px;

  @media(max-width:1100px){
    font-size: 55px;
    padding-left:0px;
  }
 
`;

export const Circle = styled.div`
  position: absolute;
  background-color: transparent;
  width: 260px;
  height: 70px;
  top: 5px;
  border: 2px solid #008000bd;
  left: 10px;
  border-radius: 50%;
  z-index: 1;
  transform: rotate(-2.25deg)

@media(max-width: 1100px){
  position: absolute;
  left: 0px !important;
  top: 0px !important;
  
}
`;
export const SubHeadingWrapper = styled.div`
// line-height:40px;
`;
export const SubHeading = styled.div`
  color: #fff;
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  font-weight: 500;
  @media(max-width:1100px){
    font-size: 30px;
  }
`;
export const ParagraphWrapper = styled.div``;
export const Paragraph = styled.div`
  color: #dfdfdf;
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  padding:5px 0;
`;
export const HeadingWrapper = styled.div`
padding: 5px 0 35px 0;
width: 100%;
`;
export const HeadingNote = styled.div`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 27px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @media(max-width: 985px){
    // flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media(max-width: 925px){
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

`;
export const ZeroSpan = styled.div`
  color: #01c26f;
  font-size: 40px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  padding: 0 8px;

  @media(max-width: 1100px){
    font-size: 35px;
  }
`;
export const ButtonWrapper = styled.div`
padding: 0 20px;
`;
export const Button = styled.button`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
  padding: 15px 28px;
  border-radius: 50px;
  font-size:18px;
  border: 1px solid #ffffff73;
  cursor: pointer;

  @media(max-width: 768px){
    font-size:20px;
  }
`;
export const RightWrapper = styled.div`
// position: relative;
  width: 50%;
  @media(max-width: 768px){
    width: 100%;
  }
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const PremiumPaymentGifWrapper = styled.div`
position: relative;
overflow: hidden;
`;
export const PremiumPaymentGif = styled.img`
width: 100%;
// width: 75%;
animation: ${rotate360} 30s linear infinite; 
`;

// export const BrandContainerWrapper = styled.div`
//   display: flex;
//   grid-template-columns: repeat(6, 1fr);
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;
//   border: 2px solid transparent;
//   border-image: linear-gradient(to bottom right,rgba(255, 255, 255, 0.28), rgba(255, 255, 255, 0.04) );
//   border-image-slice: 1;
//   padding: 25px 30px;
//   background-color: rgba(255, 255, 255, 0.04) !important;
//   border-radius: 30px;
//     `;

export const BrandContainerWrapper = styled.div`
// grid-template-columns: repeat(7, 1fr);
grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
justify-content: space-between;
align-items: center;
text-align: center;
padding: 25px 10px;
display: grid;
border: double 3px transparent;
border-radius: 10px;
background-image: linear-gradient(to right, #00000042, #000),radial-gradient(circle at bottom left, #ffffff21,#ffffff17);
background-origin: border-box;
background-clip: padding-box, border-box;
transition: grid-template-columns 0.5s ease-in-out; 
margin: 3rem 20px 0 20px;

@media(max-width: 1100px){
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}
`;


export const BrandNameWrapper = styled.div`
`;
export const BrandName = styled.img`

width: 80%;


`;




export const MobileNavbarWrapper = styled.div`
cursor:pointer;
`;
export const MobileMenuBar = styled.img`
@media(min-width: 768px){
  display:none;
  cursor:pointer;
}
`;

const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;

export const MobileViewWrapper = styled.div`
@media(min-width: 768px){
  display:none;
}
display:flex;
justify-content:flex-end;
height:100vh;
z-index:99;
animation: ${fadeIn} 0.5s ease-in-out; 
top: 0;
position: absolute;
width: 100%;
`;
export const MobileView = styled.div`

width: 80%;
background:white;
z-index: 10;

`;
export const MobileviewMenuItemWrapper = styled.div``;
export const MobileViewItem = styled.div`
padding: 20px 30px;
font-size: 20px;
font-family: "DM Sans", sans-serif;
`;

export const CloseIconWrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
text-align: center;
`;
export const CloseIcon = styled.div`
padding: 15px 25px 10px 0px;
    font-size: 32px;
`;

// const DimondIcon = keyframes`
// from {
  
//   transform: rotate(0deg);
// }
// to {

//   transform: rotate(360deg);
// }
// `;

export const DimandViewWrapper = styled.div``;
export const DimandView = styled.img`
position: absolute;
top: 23%;
left: 36%;
right: 0;

`;