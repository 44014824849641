import React from "react";
import SceamelessIcon from "./../../../Images/Sceamless.png";
import DiverseIcon from "./../../../Images/Diverse.png";
import FlexibleIcon from "./../../../Images/Flexible.png";
import IntegrationIcon from "./../../../Images/ApiIntegration.png";
import {
  Root,
  Wrapper,
  CardContainer,
  // Container,
  HeadingWrapper,
  BoldHeading,
  ParagraphWrapper,
  Paragraph,
  Card,
  CardImageWrapper,
  CardImage,
  CardImageHeadingWrapper,
  CardImageHeading,
  CardImageParaWrapper,
  CardImagePara,
  CutEffect,
  CutEffectButtonWrapper,
  CardCutEffect,
  Triangle,
  TriangleWrapper,
  TriangleOutline,
} from "./EntryPortalstyle.js";
import { Container } from "../../CommonStyledComponent/Commonstyled.js";

export const TransactionCard = () => {
  const CardData = [
    {
      icon: SceamelessIcon,
      HeadingName: "Sceameless",
      paragraph:
        "Transact cryptocurrency from anywhere in the world within a few clicks.",
    },
    {
      icon: DiverseIcon,
      HeadingName: "Diverse",
      paragraph: "An all-in-one crypto payment gateway ",
    },
    {
      icon: FlexibleIcon,
      HeadingName: "Flexible",
      paragraph: "Available 24×7 for your command from anywhere",
    },
    {
      icon: IntegrationIcon,
      HeadingName: "Api Integration",
      paragraph:
        "Explore effortless integration with our seamless integration like no other.",
    },
  ];

  return (
    <Root>
      <Container>
        <Wrapper>
          <HeadingWrapper>
            <BoldHeading>
              Your Entry to Seamless Crypto Transactions
            </BoldHeading>
          </HeadingWrapper>
          <ParagraphWrapper>
            <Paragraph>
              We possess all the essentials to thrive in the future of
              cryptocurrency payments.
            </Paragraph>
          </ParagraphWrapper>
        </Wrapper>
        <CardContainer >
         {CardData?.map((data, index) => (
           <Card key={index}>
             <CardImageWrapper>
               <CardImage src={data?.icon} alt={data?.HeadingName} />
             </CardImageWrapper>
             <CardImageHeadingWrapper>
               <CardImageHeading>{data?.HeadingName}</CardImageHeading>
             </CardImageHeadingWrapper>
             <CardImageParaWrapper>
               <CardImagePara>{data?.paragraph}</CardImagePara>
             </CardImageParaWrapper>
             <TriangleWrapper>
        {/* <TriangleOutline /> */}
        <Triangle />
      </TriangleWrapper>
           </Card>
         ))}
         </CardContainer>
      </Container>
    </Root>
  );
};
