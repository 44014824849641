import React, { useEffect, useState } from "react";
import {
  FormEmailInput,
  FormHeading,
  FormHeadingWrapper,
  FormInputText,
  FormInputWrapper,
  FormNameInput,
  FormParagraphText,
  FormParagraphWrapper,
  FormPhoneInput,
  FormTextWrapper,
  FormWrapperContainer,
  SubmitButton,
  SubmitContainer,
  SubmitWrapper,
  TopFormWrapper,
} from "./TopContactFormStyle";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Loader/Loader";
import { environmentVariables } from "../../../config/env.config";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const TopContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState()
  const [mobile, setMobile] = useState()
  const [error, setError] = useState("")

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').required("Name is required").min(2, "Required at least 3 char").max(45, "Required maximum 45 char"),
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      // mobile: Yup.string().required('Number is required'),
      // country: Yup.string().required('Country Code is required'),
      // phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      //   .min(10, "Number is too short")
      //   .max(12, "Number too long"),
    }),
    onSubmit: (values) => {
      if(!mobile){
        setError("Number is required")
      }else{
        setLoading(true)
        let data = {
          email: values?.email,
          name: values?.name,
          number: mobile,
          countryCode : country
        };
  
        let config = {
          method: 'post',
          url: `${environmentVariables?.apiUrl}api/user/postuserdata`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        console.log("pppp",data)
        axios.request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          setLoading(false)
  
          toast.success(response?.data?.message, {
            autoClose: 2000,
          });
          window.history.pushState({}, '', '?thankyou');
        })
        .catch((error) => {
          setLoading(false)
  
          console.log(error?.response?.data?.message || error?.message);
          toast.error(error?.response?.data?.message || error?.message, {
            autoClose: 20000,
          });
        });
      }
    
    }
  });

  const { handleSubmit } = formik;
  console.log("ooooooo",formik.values,country,mobile)
  // useEffect(()=>{
  //   setError("Number is required")
  // },[mobile,loading])

  return (

    <TopFormWrapper id="" onSubmit={formik.handleSubmit} onClick={()=>setError("")}>
      <FormParagraphWrapper>
        <FormParagraphText>  Fill in the below-mentioned details to  </FormParagraphText>
      </FormParagraphWrapper>

      <FormHeadingWrapper>
        <FormHeading> Get in touch with the team </FormHeading>
      </FormHeadingWrapper>


      <FormInputWrapper>
        <FormTextWrapper>
          <FormNameInput type="text" placeholder="Name" {...formik.getFieldProps("name")} />
          {formik.touched.name && formik.errors.name && (
            <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{formik.errors.name}</div>
          )}
        </FormTextWrapper>
        <FormTextWrapper EmailInputSpacingManage>
          <FormEmailInput type="text" placeholder="Email"  {...formik.getFieldProps("email")} />
          {formik.touched.email && formik.errors.email && (
            <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{formik.errors.email}</div>
          )}
        </FormTextWrapper>
        <FormTextWrapper>
        <PhoneInput
              country={"in"}
              value={mobile}
              onChange={(value, country) => {
                console.log(value, country)
                if (country && country?.dialCode) {
                  setCountry(country.countryCode.toUpperCase());
                  const originalNumber = value.slice(country.dialCode.length);
                  console.log(`+${country.dialCode}-${originalNumber}`);
                  setMobile(`${country.dialCode}-${originalNumber}`);
                } else {
                  setMobile(value);
                }
              }}
              className=""
            />
            {
              error && <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{error}</div>
            }
            </FormTextWrapper>
             {/* {formik.touched.mobile && formik.errors.mobile && (
                <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{formik.errors.mobile}</div>
              )} */}
        {/* <FormTextWrapper>
          <FormPhoneInput type="text" placeholder="Phone Number" {...formik.getFieldProps("phoneNumber")} />
          {formik.touched.phoneNumber && formik.errors.phoneNumber && (
            <div style={{ color: '#ff3333', fontSize: "12px", textAlign: "start", padding: "5px 0px 0 30px" }}>{formik.errors.phoneNumber}</div>
          )}
        </FormTextWrapper> */}

        <SubmitContainer>
          <SubmitWrapper>
            <SubmitButton onClick={handleSubmit}>{loading ? <Loader size={30} /> : "Get In Touch"}</SubmitButton>
          </SubmitWrapper>
        </SubmitContainer>
      </FormInputWrapper>
      <ToastContainer/>
    </TopFormWrapper>





  );
};

export default TopContactForm;
