import styled from "styled-components";

export const PayModeWrapper = styled.div`
max-width: 1247px;
width: 100%;
margin: 0 auto 120px auto;

@media (max-width: 1200px){
  max-width:95%;
  }
  
  @media (max-width: 992px){  
    /* padding: 0 1.5rem;   */
    font-size: 40px;   
    margin: 0 auto 80px auto;
    }
    @media (max-width: 576px){   
      max-width:95%  
      font-size: 40px;
      margin: 0 auto 30px auto;
      }    
`;

export const PayModeContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

@media (max-width: 992px){    
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin: 0;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  padding-left: 1.5rem;
}
@media (max-width: 576px){
  width:90%;
  margin: 0 auto;
}
`;

export const PayModeCard = styled.div`
max-width: 370px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 60px;
        position: relative;
position:relative;
@media (max-width: 992px){
  width: 211px;
  margin: 0;
  margin-right: 1.5rem;
  flex: 0 0 auto;
  overflow: visible;
}

`;
export const PayModeCardImg = styled.img`
max-width: 211px;
width: 100%;
margin-bottom: 40px;

@media (max-width: 992px){
  max-width:400px;
  margin-bottom: 20px;
}
`;
export const PayModeCardImgContent = styled.div`
display: flex;
flex-direction: column;
`;
export const PayModeCardHeading = styled.div`
font-family: "DM Sans", sans-serif;
font-size:24px;
font-weight:700;
color: #fff;
`;
export const PayModeCardParagraph = styled.div`
font-family: "DM Sans", sans-serif;
font-size:16px;
font-weight:400;
color: #fff;
`;
export const PayModeButton = styled.button`
  display:block;
  max-width: 297px;
  width:100%;
  height:55px;
  text-align:center;
  line-height:55px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);    
  border-radius: 50px;
  font-size: 16px;
  border: 1px solid #ffffff73;
  cursor: pointer;
  margin: 0 auto;
  @media (max-width: 992px){
    margin-top: 30px;
  }
`;