import React from 'react';
import FeeIcon from '../../../Images/minimal-fee-icon.png';
import PaymnetIcon from '../../../Images/secure-payment-icon.png';
import ApiIcon from '../../../Images/ApiIntegration.png';
import {
    FutureWrapper, FutureHeading, FutureContent, Card, CardIconWrapper, CardIcon,
    FutureCardHeading, FutureCardParagraph
} from './FutureStyled.js';
import { Container } from "../../CommonStyledComponent/Commonstyled.js";

const FutureFinance = () => {
    const CardData = [
        {
            icon: FeeIcon,
            HeadingName: "Minimal Fee",
            paragraph: "Make smooth transactions at a negligible platform fee of 0.25%",
        },
        {
            icon: PaymnetIcon,
            HeadingName: "Secure Payments",
            paragraph: "Send & receive crypto payments with our trusted gateway",
        },
        {
            icon: ApiIcon,
            HeadingName: "API integration",
            paragraph: "Scale your operations with our easy and quick API integration facility ",
        },
    ]


    return (

        <Container>
            <FutureWrapper>
                <FutureHeading> THE FUTURE OF FINANCE <br /> AWAITS!  </FutureHeading>
                <FutureContent>
                    {CardData?.map((data, index) => (
                        <Card key={index}>
                            <CardIconWrapper>
                                <CardIcon src={data?.icon} alt={data?.HeadingName} />
                            </CardIconWrapper>
                            <FutureCardHeading> {data?.HeadingName} </FutureCardHeading>
                            <FutureCardParagraph> {data?.paragraph} </FutureCardParagraph>
                        </Card>
                    ))}

                </FutureContent>
            </FutureWrapper>
        </Container>

    )
}

export default FutureFinance;