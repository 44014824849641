import React from 'react';
import CheckBoxIcon from "./../../../Images/check-square.png";
import PaymentGatwayIcon from "./../../../Images/PaymentGateway.png";
import {
    Button,
    ButtonWrapper,
    CheckBox,
    CheckBoxTextWrapper,
    CheckBoxWrapper,
    CheckboxText,
    // Container,
    Heading,
    HeadingWrapper,
    Image,
    Imagewrapper,
    LeftRightContainer,
    LeftContainer,
    OrderListItem,
    Paragraph,
    ParagraphHeading,
    RightContainer,
    Root,
    UnOrderListWrapper,
    Wrapper,
 } from '../ExchangeCard/Exchangestyle';
import { Container } from '../../CommonStyledComponent/Commonstyled';
import { useNavigate } from 'react-router';


const PaymentGatewaycomponent = () => {
  const navigate = useNavigate()
  return (
    <Root>
    <Container>
      <Wrapper>
        <LeftRightContainer>
          <LeftContainer>
            <HeadingWrapper>
              <Heading> Payment Gateway </Heading>
            </HeadingWrapper>
            <ParagraphHeading>
              <Paragraph>
              Designed for businesses and individuals seeking seamless, secure, and efficient digital transactions.
              </Paragraph>
            </ParagraphHeading>
            <UnOrderListWrapper>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Instant Settlements </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem MidExchange>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Secure and Transparent </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Diverse Crypto Support </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
            </UnOrderListWrapper>
            <ButtonWrapper>
              <Button onClick={()=> navigate('/paymentgateaway')}>Talk to Expert</Button>
            </ButtonWrapper>
          </LeftContainer>
          <RightContainer>
            <Imagewrapper>
              <Image src={PaymentGatwayIcon} />
            </Imagewrapper>
          </RightContainer>
        </LeftRightContainer>
      </Wrapper>
    </Container>
  </Root>
  )
}

export default PaymentGatewaycomponent