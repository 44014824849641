import styled from "styled-components";

export const FutureWrapper = styled.div`
max-width: 1247px;
width:100%;
margin: 0 auto 120px auto;
@media (max-width: 1200px){
  max-width:95%;
  }
  @media (max-width: 992px){
    max-width:98%;
    font-size: 40px;
    margin: 0 auto 80px auto;
    }
    @media (max-width: 576px){     
      font-size: 40px;
      margin: 0 auto 30px auto;
      }
    
`;

export const FutureHeading = styled.div`
text-align: center;
font-size: 48px;
font-weight: 700;
font-family: "DM Sans", sans-serif;
color: #fff;;
margin-bottom:70px;
@media (max-width: 992px){
  font-size: 40px;
  margin-bottom:50px;
  }
  @media (max-width: 576px){
    font-size: 32px;
    margin-bottom:20px;
    }
`;

export const FutureContent = styled.div`
width:100%;
display: flex;
justify-content: space-between;
@media (max-width: 1200px){    
    gap:20px;
}
@media (max-width: 992px){    
 flex-wrap:wrap;
 justify-content: center;
}
@media (max-width: 640px){
    flex-direction:column;
    justified-content:center;
    gap:20px;
}
`;
export const Card = styled.div`
position: relative;
max-width: 395px;
width:100%;
flex-wrap:wrap;
box-sizing: border-box;
padding: 65px 30px 30px 30px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
border: double 2px transparent;
border-radius: 10px;
background-image: linear-gradient(to right , #000000, #000000),radial-gradient(circle at bottom right, #ffffff0f,#ffffff54);
background-origin: border-box;
background-clip: padding-box, border-box;

@media (max-width: 992px){    
  max-width: 45%;
 }
@media (max-width: 640px){
  max-width: 90%;
  margin: 0 auto;
}
@media (max-width: 576px){
  padding:30px;
}
`;

export const CardIconWrapper = styled.div`
width:100px;
height: 100px;
border-radius: 100%;
border:1px solid #353535;
background: #1e1e1e;
display: flex;
justify-content: center;
align-items: center;
`
export const CardIcon = styled.img`
width:70px;
`

export const FutureCardHeading = styled.div`
font-family: "DM Sans", sans-serif;
color:#fff;
font-size:32px;
font-weight:700;
line-height:41px;
margin: 20px 0;
`
export const FutureCardParagraph = styled.div`
font-family: "DM Sans", sans-serif;
color:#fff;
font-size:16px;
font-weight:700;
line-height:26px;
`