import React from 'react';
import WalletIcon from "./../../../Images/Wallet.png";
import CheckBoxIcon from "./../../../Images/check-square.png";
import {
    CheckBox,
    CheckBoxTextWrapper,
    CheckBoxWrapper,
    CheckboxText,
    // Container,
    Heading,
    HeadingWrapper,
    Image,
    Imagewrapper,
    // LeftContainer,
    OrderListItem,
    Paragraph,
    ParagraphHeading,
    RightContainer,
    Root,
    UnOrderListWrapper,
    Wrapper,
 } from '../ExchangeCard/Exchangestyle';
import styled from 'styled-components';
import { Container } from '../../CommonStyledComponent/Commonstyled';
 
const LeftRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction:row-reverse;
justify-content: space-between;
align-items: center;
@media(max-width: 768px){
  flex-direction: column-reverse;
}
`;

export const LeftContainer = styled.div`
width: 48%;
@media(max-width: 768px){
  width: 80%;
}
@media(max-width: 550px){
  width: 100%;
  padding: 40px 20px;
}
`;

const DecentralisedWallet = () => {
  return (
    <Root>
    <Container>
      <Wrapper>
        <LeftRightContainer>
          <LeftContainer>
            <HeadingWrapper>
              <Heading> Decentralized Wallet App</Heading>
            </HeadingWrapper>
            <ParagraphHeading>
              <Paragraph>
              Our state-of-the-art wallet app redefines the way you manage and transact with your cryptocurrencies.
              </Paragraph>
            </ParagraphHeading>
            <UnOrderListWrapper>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Seamless Integration</CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem MidExchange>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText> Ultimate Control </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Highly Secured </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
            </UnOrderListWrapper>
          
          </LeftContainer>
          <RightContainer>
            <Imagewrapper>
              <Image src={WalletIcon} />
            </Imagewrapper>
          </RightContainer>
        </LeftRightContainer>
      </Wrapper>
    </Container>
  </Root>
  )
}

export default DecentralisedWallet