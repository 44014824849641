import React from 'react';
import Paymobile from '../../../Images/pay_mobile.png';
import mobile1Props from '../../../Images/mobile-props1.png';
import mobile2Props from '../../../Images/mobile-props2.png';
import mobile3Props from '../../../Images/mobile-props3.png';
import mobile1 from '../../../Images/mobile1.png';
import mobile2 from '../../../Images/mobile2.png';
import mobile3  from '../../../Images/mobile3.png';


import { Container } from "../../CommonStyledComponent/Commonstyled.js";
import {  FutureHeading } from '../FutureFinance/FutureStyled';
import { PayModeWrapper, PayModeContent, PayModeCard, PayModeCardImg, PayModeCardImgContent, 
    PayModeCardHeading, PayModeCardParagraph, PayModeButton } from './PayModeStyled.js';

const PayMode = () => {
    const CardData = [
        {
            icon: mobile1,
            mprops:mobile1Props,
            HeadingName: "Pay Instantly",
            paragraph: "Secure swift transactions with a tap",
        },
        {
            icon: mobile2,
            mprops:mobile2Props,
            HeadingName: "Generate Payment QR",
            paragraph: "Simplified QR creation for seamless payments",
        },
        {
            icon: mobile3,
            mprops:mobile3Props,
            HeadingName: "Send Payment Link",
            paragraph: "Effortless transactions with customizable links",
        },
    ]
    return (
        <>
            <Container>
                <PayModeWrapper>
                    <FutureHeading> PAY VIA 3 DIFFERENT <br/> MODES </FutureHeading>
                    <PayModeContent> 
                        {CardData?.map((data, index) => (
                        <PayModeCard key={index}>                           
                                <PayModeCardImg src={data?.icon} alt={data?.HeadingName} />  
                                <PayModeCardImgContent>
                                    <PayModeCardHeading> {data?.HeadingName} </PayModeCardHeading>
                                    <PayModeCardParagraph> {data?.paragraph} </PayModeCardParagraph>
                                </PayModeCardImgContent>   
                                <div className={`props${index+1}`}> <img src={data?.mprops} /> </div>
                        </PayModeCard>
                    ))}
                    
                    </PayModeContent>     
                    <PayModeButton> <a href='#bottom'> Streamline My Payments </a> </PayModeButton>          
                </PayModeWrapper>
               
            </Container>

        </>
    )
}

export default PayMode