import styled from "styled-components";
import CurrencyBgImage from "./../../../Images/CurrencyBg.png";

export const Root = styled.div`
  background-color: #000;
  background-image: url(${CurrencyBgImage});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 62vh;
  display: flex;
  align-items: center;
  background-position: center;
`;
export const Container = styled.div`
  width: 1215px;
  margin: 0 auto;
  padding-bottom: 40px;
  @media(max-width: 1300px){
    width: 100%;

  }
`;
export const Wrapper = styled.div`
  width: 100%;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
export const Heading = styled.div`
  font-weight: 900;
  font-size: 70px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  text-transform: uppercase;
  width: 55%;
  padding: 0 10px;

  @media(max-width: 1150px){
    width: 60%;
  }

  @media(max-width: 1024px){
    width: 70%;
    font-size: 50px;
  }
  @media(max-width: 768px){
    width: 100%;
    font-size: 50px;
  }
  @media(max-width: 550px){
    width: 100%;
    font-size: 38px;
  }
  
`;
export const ParagraphWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 30px 0 20px 0;
`;
export const Paragraph = styled.div`
  font-weight: 500;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  width: 55%;

  @media(max-width: 768px){
    width: 100%;
    padding: 0 20px;
  }
`;
