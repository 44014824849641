import React, { useState } from "react";
import CircleTopHandImage from "./../../../Images/CircleTophand.png";
// import CircleTop from "./../../../Images/circletop.png";
import CircleTop from "./../../../Images/CircleTop2.png";
import LBankIcon from "./../../../Images/Lbank.png";
import Bitmart from "./../../../Images/Bitmart.png";
import CoinDcxIcon from "./../../../Images/CoinDcx.png";
import mexcglobalIcon from "./../../../Images/mexcglobal.png";
import xtcom from "./../../../Images/xtcom.png";
import QuickswapIcon from "./../../../Images/quickswap.png";
import BrandIconuniswap from "./../../../Images/BrandIconuniswap.png";
import DimandImg from "./../../../Images/etherium.gif";
import styled from "styled-components";
import BackgroundImage from '../../../Images/PaymentBg.png';
import {
  BrandContainerWrapper,
  BrandName,
  BrandNameWrapper,
  Button,
  ButtonWrapper,
  Circle,
  DimandView,
  HandCircle,
  HandCircleWrapper,
  HeaadingBold,
  HeaadingBold2,
  HeadingBoldWrapper, 
  LeftRightWrapper,
  LeftWrapper,
  Paragraph,
  ParagraphWrapper,
  PremiumPaymentContainer,
  PremiumPaymentGif,
  PremiumPaymentGifWrapper,
  RightWrapper,

  SubHeading,
  SubHeadingWrapper,  
} from "../Navigation/Navbarstyle";
import { Container } from "../../CommonStyledComponent/Commonstyled";


const Root = styled.div`
background-color: #000;
background-image: url(${BackgroundImage});
background-size: cover;
width: 100%;
padding-bottom:  3rem;
`;

export const HeroBanner = () => {
  const BrandIcon = [
    { Icon: LBankIcon },
    { Icon: Bitmart },
    { Icon: CoinDcxIcon },
    { Icon: xtcom },
    { Icon: BrandIconuniswap },
    { Icon: QuickswapIcon },
  ];

  const [IsClick, setIsClick] = useState(false);

  const ToggleHandler = () => {
    setIsClick(!IsClick);
  };
  const CloseHandle = () => {
    setIsClick(false);
  };

  return (
    <>
      <Container>       
        <LeftRightWrapper>
          <LeftWrapper>
            <PremiumPaymentContainer>
              <HeadingBoldWrapper>
                <HeaadingBold> Premium</HeaadingBold>
               <div style={{position:"relative"}}>
               <HeaadingBold2>Payment </HeaadingBold2>
                <Circle />
               </div>
              </HeadingBoldWrapper>
              <SubHeadingWrapper>
                <SubHeading>
                  Solutions for a Successful Leap Forward!
                </SubHeading>
              </SubHeadingWrapper>
              <ParagraphWrapper>
                <Paragraph>
                  While instant growth may not have a guaranteed formula,
                  FloxyPay offers a solution for seamless transactional
                  experiences in both cryptocurrency and fiat.
                </Paragraph>
              </ParagraphWrapper>

              {/* <HeadingWrapper>
                <HeadingNote>
                  {" "}
                  Get <ZeroSpan>Zero</ZeroSpan>Gas Fee for first 3 months
                </HeadingNote>
              </HeadingWrapper> */}
            </PremiumPaymentContainer>
            <ButtonWrapper style={{
              marginTop:"30px"
            }}>
              <Button>  <a href="#contactformsection">  Get in touch  </a></Button>
            </ButtonWrapper>
          </LeftWrapper>
          <RightWrapper>
            <PremiumPaymentGifWrapper>
              <PremiumPaymentGif src={CircleTop} />
              <DimandView src={DimandImg} />
            </PremiumPaymentGifWrapper>
            {/* <DimandViewWrapper>
              <DimandView src={DimandImg} />
            </DimandViewWrapper> */}
            <HandCircleWrapper>
              <HandCircle src={CircleTopHandImage} />
            </HandCircleWrapper>
          </RightWrapper>
        </LeftRightWrapper>

        <BrandContainerWrapper>
          {BrandIcon?.map((BrandIcondata, index) => (
            <BrandNameWrapper key={index}>
              <BrandName src={BrandIcondata?.Icon} />
            </BrandNameWrapper>
          ))}
        </BrandContainerWrapper>
      </Container>
    </>
  );
};
