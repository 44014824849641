import styled from "styled-components";

export const Root = styled.div`
background-color: #000;
width: 100%;

`;
export const Container = styled.div`
width: 100%;
`;
export const Container2 = styled.div`
width: 100%;
height: 70px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
background-color: #323232;
// padding: 0 20px;

@media(max-width: 425px){
    height: 40px;
}
`;
export const MenuOrdererdListWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
height:70px;
background-color: #323232;
`;
export const Certificatewrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
height:70px;
background-color: #e7e7e7;
`;
export const MenuOrdererdList = styled.div`
font-size: 17px;
font-weight: 700;
font-family: "Lato";
color: #fff;
padding: 0 20px;
cursor: pointer;

@media(max-width: 768px){
    font-size: 16px;
font-weight: 500;
padding: 0 10px;
}
@media(max-width: 425px){
    font-size: 12px;
font-weight: 400;
padding: 0 5px;
}
`;

export const CopyWriteContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width: 425px){
    padding: 0 10px;
}
`;
export const CopyWriteText = styled.div`
font-weight: 500;
font-size: 14px;
font-family: "DM Sans", sans-serif;
color: #fff;
padding: 20px 0;
`;

export const Span = styled.span`
font-weight: 500;
font-size: 14px;
font-family: "DM Sans", sans-serif;
color: #fff;
padding: 20px 10px;
`;

export const SocialMediaLinkWrapper = styled.div`
padding: 40px 0;
width:100%;
display:flex;
justify-content:center;
`;
export const SocialMediaLink = styled.img`
padding: 0 20px;
cursor: pointer;
`;