import styled from "styled-components";

export const Root = styled.div`
  background-color: #000;
  width: 100%;
  // height: 100vh;
`;
export const Container = styled.div`
  width: 1215px;
  margin: 0 auto;
  @media (max-width: 1300px) {
    width: 95%;
  }
`;
export const Wrapper = styled.div`
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${(p) =>
    p.PressWrapper &&
    `
padding: 0 20px 70px 20px;
`}
`;
export const HeadingWrapper = styled.div`
  width: 100%;
  padding-top:30px;
  @media(max-width: 768px){
    padding-top:10px;
  }
`;
export const Heading = styled.div`
  font-weight: 700;
  font-size: 38px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  @media(max-width: 768px){
    font-size: 24px;
  }
`;
export const ParagraphWrapper = styled.div`
  width: 63%;
  padding: 15px 25px;
  @media(max-width: 768px){
    width: 85%;
    padding: 20px 0;
  }
`;
export const Paragraph = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  @media(max-width: 768px){
    font-size: 16px;
  }
`;

// export const FormWrapperContainer = styled.div`
// width: 40%;
// background-color: rgba(255, 255, 255, 0.04) !important;
//   border: 2px solid transparent;
//   border-image: linear-gradient(to bottom right,rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.10));
//   border-image-slice: 1;
//   padding: 25px 28px 15px 28px;
// //   border-radius: 30px;
//   border-bottom-right-radius: 25px;
//   margin: 30px 0;

// `;

export const FormWrapperContainer = styled.div`
  width: 40%;
  margin-top: 40px;
  // grid-template-columns: repeat(6, 1fr);
  // justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 25px 30px;
  display: grid;
  border: double 3px transparent;
  border-radius: 10px;
  background-image: linear-gradient(to right, #00000042, #000),
  radial-gradient(circle at top right, #ffffff21, #ffffff17);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 65%;
  }
  @media (max-width: 576px) {
    width: 80%;
  }
  @media (max-width: 425px) {
    width: 90%;
    padding: 25px 15px;
  }
`;

export const FormHeadingWrapper = styled.div``;
export const FormHeading = styled.div`
  font-weight: 700;
  font-size: 40px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;
export const FormParagraphWrapper = styled.div``;
export const FormParagraphText = styled.div`
  font-weight: 400;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;

export const FormInputWrapper = styled.div`
  margin-top: 40px;
`;
export const FormTextWrapper = styled.div`
  ${(p) =>
    p.EmailInputSpacingManage &&
    `
    margin: 15px 0;
`}
`;
export const FormNameInput = styled.input`
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: 85%;
  padding: 14px;
  color: #fff;
  outline: none;
`;
export const FormEmailInput = styled.input`
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: 85%;
  padding: 14px;
  color: #fff;
  outline: none;
`;
export const FormPhoneInput = styled.input`
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  width: 85%;
  padding: 14px;
  color: #fff;
  outline: none;
`;

export const SubmitWrapper = styled.div`
  padding: 16px 25px;
  background-color: #fff;
  margin: 35px 0;
  border-radius: 42px;
  border: none;
  width: 115px;
`;
export const SubmitButton = styled.div`
  color: #343434;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PressHeadingWrapper = styled.div``;
export const PressHeadingText = styled.div`
  font-weight: 700;
  font-size: 55px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;
export const PressParagraphWrapper = styled.div``;
export const PressParagraph = styled.div`
  font-weight: 500;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
  color: #848282;
  // padding-top:2px;
`;

export const BrandContainerWrapper = styled.div`
  margin-top: 45px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 25px 30px;
  border: 1px solid #ffffff21;
  border-radius: 8px;
  
  @media(max-width: 768px){
    width: 95%;
    padding: 25px 5px;
    margin-top: 15px;
  }
`;

export const BrandNameWrapper = styled.div`
  cursor: pointer;
  @media(max-width: 425px){
    margin: 0 10px;
  }
`;
export const BrandName = styled.img`

width: 70%;

@media(max-width: 768px){
  width: 85%;
}

@media(max-width: 425px){
  width: 100%;
}
`;

export const BrandLink = styled.a``;