import React from "react";
import styled from "styled-components";
import AusBgImg from "./../../../Images/Australia.png";
import DubaiBgImg from "./../../../Images/Dubai.png";
import NigeriaImg from "./../../../Images/Nigeria.png";
import ColoradoImg from "./../../../Images/Colorado.png";
import CanadaImg from "./../../../Images/Canada.png";
import PolandImg from "./../../../Images/Poland.png";
import {
  Container,
  CountryName,
  CountryNameWrapper,
  CountryParagraph,
  CountryParagraphWrapper,
  ForeignCardWrapper,
  Heading,
  HeadingWrapper,
  Root,
  Wrapper,
} from "./WorldDataStyle";



const Foreigncard = styled.div`
  background-image: url(${props => props.countryImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: 200px;
  max-height:220px;
  max-width: 225px;
  min-width:225px;
  @media(max-width: 576px){
    max-width: 300px;
    min-width:300px;
  }
 
`;

const WorldData = () => {
  const CountryData = [
    {
      CountryName: "Australia",
      CountryAddress: "81-83 Campbell Street Surry Hills NSW 2010 Australia",
      CountryImage: AusBgImg,
    },
    {
      CountryName: "Dubai",
      CountryAddress:
        "Office 121, KML bulding,Al meydan road, KML bulding, Dubai (UAE) ",
      LSRSnumber:
        "License No. 1073966 Registration Number 2017974",
      CountryImage: DubaiBgImg,
    },
    {
      CountryName: "Nigeria",
      CountryAddress:
        "No. 7, house 4, Winston churchill street, asokoro abuja, FCT, Nigeria",
      CountryImage: NigeriaImg,
    },
    {
      CountryName: "Colorado",
      CountryAddress: "1312 17th Street Suite 1220 , Denver, Colorado 80202",
      CountryImage: ColoradoImg,
    },
    {
      CountryName: "Canada",
      CountryAddress: "1376-1771 Robson ST, Vancouver BC V6G 1C9, Canada",
      CountryImage: CanadaImg,
    },
    {
      CountryName: "Poland",
      CountryAddress: "Ul. Chodakowska 53/57-22a Warszawa, mazowieckie 03-816 Poland",
      CountryImage: PolandImg,
    },
  ];

  // License No. 1073966 Registration Number 2017974

  return (
    <Root>
      <Container>
        <Wrapper>

          {/* <div> */}
          <HeadingWrapper>
            <Heading>Our offices around the world</Heading>
          </HeadingWrapper>

          <ForeignCardWrapper>
            {CountryData.map((Country, index) => (
              <Foreigncard countryImage={Country.CountryImage}>
                <CountryNameWrapper>
                  <CountryName> {Country?.CountryName} </CountryName>
                </CountryNameWrapper>
                <CountryParagraphWrapper>
                  <CountryParagraph>{Country?.CountryAddress} </CountryParagraph>

                  <CountryParagraph style={{ paddingTop: "10px" }}>   {Country?.LSRSnumber}</CountryParagraph>
                </CountryParagraphWrapper>
              </Foreigncard>
            ))}
          </ForeignCardWrapper>
          {/* </div> */}

         
        </Wrapper>
      </Container>
    </Root>
  );
};

export default WorldData;
