import React from 'react';
import FxyTokenImage from "./../../../Images/FxyToken.png";
import CheckBoxIcon from "./../../../Images/check-square.png";
import {
    CheckBox,
    CheckBoxTextWrapper,
    CheckBoxWrapper,
    CheckboxText,
    // Container,
    Heading,
    HeadingWrapper,
    Image,
    // LeftContainer,
    OrderListItem,
    Paragraph,
    ParagraphHeading,
    RightContainer,
    Root,
    UnOrderListWrapper,
    Wrapper,
 } from '../ExchangeCard/Exchangestyle';
import styled from 'styled-components';
import { Container } from '../../CommonStyledComponent/Commonstyled';
 
const LeftRightContainer = styled.div`
width: 100%;
display: flex;
flex-direction:row-reverse;
justify-content: space-between;
align-items: center;
@media(max-width: 768px){
  flex-direction: column-reverse;
}
`;

 const LeftContainer = styled.div`
width: 48%;

@media(max-width: 768px){
  width: 80%;
}
@media(max-width: 550px){
  width: 100%;
  padding: 40px 20px;
}
`;

export const Imagewrapper = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
@media(max-width: 768px){
  justify-content: center;
}
`;

const FxyTokenComponent = () => {
  return (
    <Root>
    <Container>
      <Wrapper>
        <LeftRightContainer>
          <LeftContainer>
            <HeadingWrapper>
              <Heading> FXY Token </Heading>
            </HeadingWrapper>
            <ParagraphHeading>
              <Paragraph>
              A single token offers a myriad of benefits, transforming the way you engage with the crypto economy. </Paragraph>

            </ParagraphHeading>
            <UnOrderListWrapper>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Cost-Efficient Transactions </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem MidExchange>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText> Integrated Ecosystems </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
              <OrderListItem>
                <CheckBoxWrapper>
                  <CheckBox src={CheckBoxIcon} />
                </CheckBoxWrapper>
                <CheckBoxTextWrapper>
                  <CheckboxText>Community Governance </CheckboxText>
                </CheckBoxTextWrapper>
              </OrderListItem>
            </UnOrderListWrapper>
          
          </LeftContainer>
          <RightContainer>
            <Imagewrapper>
              <Image src={FxyTokenImage} />
            </Imagewrapper>
          </RightContainer>
        </LeftRightContainer>
      </Wrapper>
    </Container>
  </Root>
  )
}

export default FxyTokenComponent