import styled from "styled-components";

export const BottomContactWrapper = styled.div`
max-width: 500px;
width: 90%;
display:flex;
justified-contact:center;
margin: 80px auto 0 auto;
@media (max-width:576px){
    margin: 30px auto 0 auto;
}
`;