import styled from "styled-components";

export const Root = styled.div`
  background-color: #000;
`;
// export const Container = styled.div`
//   width: 1215px;
//   margin: 0 auto;

//   @media(max-width: 1300px){
//     width: 100%;
    

//   }
// `;


export const LeftRightContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;

@media(max-width: 768px){
  flex-direction: column-reverse;
}


`;
export const LeftContainer = styled.div`
width: 48%;

@media(max-width: 768px){
  width: 80%;
}
@media(max-width: 550px){
  width: 100%;
  padding: 0px;
}
`;
export const Wrapper = styled.div`
padding: 45px 20px 60px 20px;
@media(max-width: 550px){
  padding: 20px 20px 20px 20px;
}
`;
export const HeadingWrapper = styled.div``;
export const Heading = styled.div`
color: #fff;
font-family: "DM Sans", sans-serif;
font-weight: 700;
font-size:68px;

@media(max-width: 1024px){
  font-size: 55px;
}
@media(max-width: 576px){
  font-size: 36px;
}

`;
export const ParagraphHeading = styled.div`
padding: 10px 0 50px 0;

@media(max-width: 1024px){
  padding: 10px 0 40px 0;
}

`;
export const Paragraph = styled.div`
color: #fff;
font-family: "DM Sans", sans-serif;
font-weight: 400;
font-size:18px;
`;
export const UnOrderListWrapper = styled.div``;
export const OrderListItem = styled.div`
display:flex;
justify-content: flex-start;
align-items: center;
${(p)=>p.MidExchange && `
padding: 20px 0;
`}

`;
export const CheckBoxWrapper = styled.div``;
export const CheckBox = styled.img`
width: 100%;

@media(max-width: 900px){
  width: 80%;
}
`;
export const CheckBoxTextWrapper = styled.div``;
export const CheckboxText = styled.div`
color: #fff;
font-family: "DM Sans", sans-serif;
font-weight: 700;
padding: 0 15px;
font-size:24px;
@media(max-width: 900px){
  font-size:24px;
  font-weight: 600;
}
@media(max-width: 600px){
  font-size:21px;
  font-weight: 600;
}
`;


export const RightContainer = styled.div`
width: 46%;

@media(max-width: 768px){
  width: 80%;
}
@media(max-width: 550px){
  width: 100%;
}
`;
export const Imagewrapper = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
@media(max-width: 768px){
  justify-content: center;
}
`;
export const Image = styled.img`
width: 95%;
`;

export const ButtonWrapper = styled.div`
margin: 50px 0;

@media(max-width: 550px){
display:flex;
justify-content:center;
align-items:center;
text-align:center;
}

`;
export const Button = styled.button`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.10);
  padding: 15px 28px;
  border-radius: 50px;
  font-size:18px;
  border: 1px solid #ffffff73;
  cursor: pointer;
`;