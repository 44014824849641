import React, { useEffect, useRef } from 'react';
import { TransactionCard } from '../../Component/LandingPage/EntryPortal/TransactionCard';
import { CryptoExchange } from '../../Component/LandingPage/ExchangeCard/CryptoExchange';
import DecentralisedWallet from '../../Component/LandingPage/WalletComponent/DecentralisedWallet';
import PaymentGatewaycomponent from '../../Component/LandingPage/PaymentGatway/PaymentGatewaycomponent';
import FxyTokenComponent from '../../Component/LandingPage/FxyToken/FxyTokenComponent';
import { DigitalCurrency } from '../../Component/LandingPage/DigitalCurrency/DigitalCurrency';
import ContactForm from '../../Component/LandingPage/Contact/ContactForm';
import WorldData from '../../Component/LandingPage/AroundWorld/WorldData';
import { HeroBanner } from '../../Component/LandingPage/HeroBanner/HeroBanner';
import styled from 'styled-components';
import BackgroundImage from '../../Images/PaymentBg.png';

const Root = styled.div`
  background-color: #000;
  background-image: url(${BackgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
`;

const Home = ({data,setMoveToBottom}) => {
  const contactFormRef = useRef(null);

  useEffect(() => {
    if(data){
      if(contactFormRef != null){
        contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
        setMoveToBottom(false)
      }
     
    }
  }, [data])
  
  

  return (
    <Root>
   <HeroBanner />
    <TransactionCard />
    <CryptoExchange />    
    <DecentralisedWallet />
    <PaymentGatewaycomponent />
    <FxyTokenComponent />
    <DigitalCurrency />
    <ContactForm id={contactFormRef}/>
    <WorldData />
    </Root>
  )
}

export default Home