import styled from "styled-components";
import bgLeft from '../../../Images/crypto-left-bg.png';
import bgRight from '../../../Images/crypto-right-bg.png';
import cryptoEllipse from '../../../Images/crypto-ellipse.png';

export const CryptoContainer = styled.div`
margin:110px 0;
 width:100%;
 display:flex;
 justify-content:center;
 flex-direction: column;
 align-items: center;
 background-image:  url(${bgLeft}),  url(${bgRight});
    background-position: -120px bottom , right -120px  bottom ;
  background-repeat:  no-repeat; 
  @media (max-width:768px){
    margin:55px 0;
  }
 `;

export const CryptoContent = styled.div`
 max-width:630px;
 height: 620px;
 width:100%;
 background:  url(${cryptoEllipse});
    background-position:  -25px -23px;
  background-repeat:  no-repeat; 
 position: relative;
 z-index: 500;
 @media (max-width:768px){
  display: flex;
    align-items: center;
    height: 250px;
    flex-wrap: wrap;
    justify-content: center;
 }

 `;
export const CryptoButton= styled.button`
position: absolute;
display: flex;
justify-content: center;
align-items: center;
padding: 4px 45px 4px 4px;   
color: #fff;
background-color: rgba(255, 255, 255, 0.10);
border-radius: 50px;
font-size: 16px;
border: 1px solid #ffffff73;
cursor: pointer;
`;
export const ButtonOne = styled(CryptoButton)`
  left: 80px;
  top:10px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    position: relative;
    margin:10px;
  }
`;
export const ButtonTwo = styled(CryptoButton)`
  left: 300px;
  top: 110px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    position: relative;
    margin:10px;
  }
`;

export const ButtonThree = styled(CryptoButton)`
  right: -100px;
  top: 191px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    right: auto;
    position: relative;
    margin:10px;
  }
`;
export const ButtocnFour = styled(CryptoButton)`
  left: 0;
  top: 290px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    position: relative;
    margin:10px;
  }
`;

export const ButtonFive = styled(CryptoButton)`
  left: 235px;
  top: 270px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    position: relative;
    margin:10px;
  }
`;

export const ButtonSix = styled(CryptoButton)`
  left: 160px;
  top: 460px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    position: relative;
    margin:10px;
  }
`;

export const ButtonSeven = styled(CryptoButton)`
  right: 20px;
  top: 480px;
  @media (max-width:768px){
    left:auto;
    top: auto;
    right: auto;
    position: relative;
    margin:10px;
  }
`;

export const CryptoIcon= styled.img`
width:40px;
margin-right: 6px;
`;
export

const CryptoImage = styled.img`
 display:block;
 width:580px;
 `;