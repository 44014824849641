import React from "react";
import {
  Container,
  Heading,
  HeadingWrapper,
  Paragraph,
  ParagraphWrapper,
  Root,
  Wrapper,
} from "./DigitalCurrencyStyled";

export const DigitalCurrency = () => {
  return (
    <Root>
      <Container>
        <Wrapper>
          <HeadingWrapper>
            <Heading>Empowering Digital Currency</Heading>
          </HeadingWrapper>
          <ParagraphWrapper>
            <Paragraph>
              Streamlining digital currency payment integration for diverse
              business requirements.
            </Paragraph>
          </ParagraphWrapper>
        </Wrapper>
      </Container>
    </Root>
  );
};
