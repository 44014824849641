
import styled, { keyframes } from "styled-components";

export const Root = styled.div`
&:hover {
  div {
    animation-play-state: paused;
  }
}

`;
const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100vw);
  }
  50% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-100vw);
  }
`;
export const CoinContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;
padding: 0 8px;
white-space: nowrap;
animation: ${marqueeAnimation} 15s linear infinite;


`;

export const Container = styled.div`
width: 100%;
height: 55px;
background-color:#000;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
overflow: hidden;
`;

export const TextImageWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
text-align: center;

`;
export const ImageContainer = styled.div`
padding: 0 10px;

`;
export const Image = styled.img``;
export const TokenNameText = styled.div`
font-size:16px;
color: #fff;
font-weight:500;
font-family: 'Plus Jakarta Sans', sans-serif;

`;
export const TokenPriceWrapper = styled.div`
// padding: 0 10px;
padding: 0 10px 0px 15px;
`;
export const TokenPrice = styled.div`
font-size: 16px;
color: #fff;
font-weight:400;
font-family: 'Plus Jakarta Sans', sans-serif;
`;
export const TokenProfitRatio = styled.div``;
export const TokenProfitPercentage = styled.div`
font-size: 15px;
color: ${(p) => (p.ProfitPercentage ? '#47F8C3' : '#F84752')};
font-weight: 400;
`;


