import React, { useState } from "react";
import EthIcon from "./../../../Images/ETH.png";
import BitCoinIcon from "./../../../Images/BitCoin.png";
import LUNAIcon from "./../../../Images/LUNA.png";
import TetherIcon from "./../../../Images/Tether.png";
import UniswapIcon from "./../../../Images/uniswap.png";
import DogeIcon from "./../../../Images/Doge.png";

import {
  CoinContainer,
  Container,
  Image,
  ImageContainer,
  MarqueeContainer,
  Root,
  TextImageWrapper,
  TokenNameText,
  TokenPrice,
  TokenPriceWrapper,
  TokenProfitPercentage,
  TokenProfitRatio,
} from "./TopBarStyle";

export const TopBar = () => {
  const coins = [
    {
      icon: EthIcon,
      name: "Ethereum",
      price: "757.36 USD",
      profitPercentage: "+0.35%",
    },
    {
      icon: BitCoinIcon,
      name: "Bitcoin",
      price: "993.32 USD",
      profitPercentage: "-0.11%",
    },
    {
      icon: LUNAIcon,
      name: "LUNA",
      price: "351.73 USD",
      profitPercentage: "+0.15%",
    },
    {
      icon: TetherIcon,
      name: "Tether",
      price: "216.92 USD",
      profitPercentage: "+1.35%",
    },
    {
      icon: UniswapIcon,
      name: "Uniswap",
      price: "579.25 USD",
      profitPercentage: "-2.62%",
    },
    {
      icon: DogeIcon,
      name: "Dogecoin",
      price: "831.36 USD",
      profitPercentage: "+1.14%",
    },
  ];
  return (
    <Root>
   <Container>    
   
    {coins.map((coin, index) => (
   
         <CoinContainer key={index}>
         <TextImageWrapper>
           <ImageContainer>
             <Image src={coin?.icon} />
           </ImageContainer>
           <TokenNameText>{coin?.name}</TokenNameText>
         </TextImageWrapper>
         <TokenPriceWrapper>
           <TokenPrice>{coin?.price}</TokenPrice>
         </TokenPriceWrapper>
         <TokenProfitRatio>
           <TokenProfitPercentage
             style={{
               color: coin.profitPercentage.includes("-")
                 ? "#F84752"
                 : "#47F8C3",
             }}
           >
             {coin?.profitPercentage}
           </TokenProfitPercentage>
         </TokenProfitRatio>
       </CoinContainer>
  ))}
   </Container>
    </Root>
  );
};
