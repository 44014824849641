import styled from "styled-components";

export const TopWrapper = styled.div`
max-width: 1188px;
width:100%;
position: relative;
display:flex;
align-items: center;
justify-content: space-between;
margin: 0 auto;
padding: 70px 0 90px 0;
@media (max-width: 1200px){
  width:95%;
  }
@media (max-width: 992px){
flex-direction: column;
padding: 30px 0 50px 0;
}
@media (max-width: 576px){
  flex-direction: column;
  padding: 40px 0 0 0;
  }
`;

export const TopWrapperLeft = styled.div`
max-width: 596px;
width:100%;
position: relative;
display:flex;
flex-direction:column;
@media (max-width: 992px){
align-items: center;
margin-bottom: 60px;
text-align:center;
@media (max-width:576px){
  margin-bottom:30px;
}
`;

export const TopWrapperRight = styled.div`
max-width: 478px;
width:100%;
@media (max-width: 992px){
  max-width:90%;
  }
`;

export const TopSubHeading = styled.h1` 
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height:74px;
  margin:0;
  padding:0;
  @media(max-width:1200px){
    font-size: 54px;
    line-height: 64px;
  }
  @media (max-width: 992px){
    font-size: 44px;
  line-height: 54px;
}
@media (max-width: 576px){
  font-size: 36px;
  line-height:46px;
}
`;
export const TopParagraph = styled.p`
  color: #fff;
  font-family: "DM Sans", sans-serif;
  font-weight:500;
  line-height:31px;
  font-size: 24px;
  margin:0 0 20px 0;  
  padding:0;
  @media (max-width: 576px){
      font-size: 20px;
      line-height:27px;
  }
`;

export const TopFormWrapper = styled.div`
  width:100%;
  box-sizing:border box;
  background:#000;  
    align-items: center;
    text-align: center;
    padding: 30px 36px;
    border: double 3px transparent;
    border-radius: 10px;
    background-image: linear-gradient(to right, #00000042, #000), radial-gradient(circle at top right, #ffffff21, #ffffff17);
    background-origin: border-box;
    background-clip: padding-box, border-box;
`;