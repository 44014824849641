import styled from "styled-components";


export const TopFormWrapper = styled.div`
  width:100%;  
  background:#000;    
    align-items: center;
    text-align: center;
    padding: 30px 36px;
    border: double 3px transparent;
    border-radius: 10px;
    background-image: linear-gradient(to right, #00000042, #000), radial-gradient(circle at top right, #ffffff21, #ffffff17);
    background-origin: border-box;
    box-sizing: border-box;
    background-clip: padding-box, border-box;
`;

export const HeadingWrapper = styled.div`
  width: 100%;
  @media(max-width: 768px){
    padding-top:10px;
  }
`;
export const Heading = styled.div`
  font-weight: 700;
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;
export const ParagraphWrapper = styled.div`
  @media(max-width: 768px){
    width: 85%;
    padding: 20px 0;
  }
`;
export const Paragraph = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;


export const FormWrapperContainer = styled.div`
  width: 40%;
  margin-top: 40px; 
  align-items: center;
  text-align: center;
  padding: 25px 30px;
  display: grid;
  border: double 3px transparent;
  border-radius: 10px;
  background-image: linear-gradient(to right, #00000042, #000),
  radial-gradient(circle at top right, #ffffff21, #ffffff17);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 65%;
  }
  @media (max-width: 550px) {
    width: 70%;
  }
  @media (max-width: 425px) {
    width: 90%;
    padding: 25px 15px;
  }
`;

export const FormHeadingWrapper = styled.div``;
export const FormHeading = styled.div`
  font-weight: 700;
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;
export const FormParagraphWrapper = styled.div``;
export const FormParagraphText = styled.div`
  font-weight: 400;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;

export const FormInputWrapper = styled.div`
  margin-top: 40px;
`;
export const FormTextWrapper = styled.div`
  ${(p) =>
    p.EmailInputSpacingManage &&
    `
    margin: 15px 0;
`}
`;
export const FormNameInput = styled.input`
width:100%;
box-sizing:border-box;
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 14px;
  color: #fff;
  outline: none;
  height: 50px;
`;
export const FormEmailInput = styled.input`
width:100%;
box-sizing:border-box;
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1); 
  padding: 14px;
  color: #fff;
  outline: none;
  height: 50px;
`;
export const FormPhoneInput = styled.input`
width:100%;
box-sizing:border-box;
  border-radius: 50px;
  border: none;
  background-color: rgba(255, 255, 255, 0.1); 
  padding: 14px;
  color: #fff;
  outline: none;
`;

export const SubmitWrapper = styled.div`
  padding: 16px 25px;
  background-color: #fff;
  margin: 35px 0 0 0;
  border-radius: 42px;
  border: none;
  width: 115px;
`;
export const SubmitButton = styled.div`
font-weight:700;
  color: #343434;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  cursor: pointer;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PressHeadingWrapper = styled.div``;
export const PressHeadingText = styled.div`
  font-weight: 700;
  font-size: 55px;
  font-family: "DM Sans", sans-serif;
  color: #fff;
`;
export const PressParagraphWrapper = styled.div``;
export const PressParagraph = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  color: #848282;
  // padding-top:2px;
`;

export const BrandLink = styled.a``;