import React, { useEffect } from 'react';
import { NavBar } from '../../Component/LandingPage/Navigation/NavBar';
import { Footer } from '../../Component/LandingPage/Footer/Footer';
import "./privacy.css";

const PrivacyPolicy = () => {
    useEffect(() => {
        if (window !== undefined) {
          window.scrollTo(0, 0);
        }
      }, []);
    return (
        <div className='privacy-container'>           
            <div className='pwrapper'>
                <section className='policy-sec'>
                <h1> Privacy Policy </h1>
                <p>
                    Please note that this privacy statement will regularly be updated to reflect any changes in the way we handle 
                    your personal information or any changes in applicable laws.   This Privacy Notice describes the policies and 
                    procedures of Floxypay Services Provider Limited, (“we,” “our,” or “us”) pertaining to the collection, use, 
                    and disclosure of your information on www.floxypay.io and related mobile
                    applications and products we offer (the “Services” or “FLOXYPAY Wallet”).
                </p>
                </section>


                <section className='policy-sec'>
                <h2> OVERVIEW </h2>
                <p>
                
Your right to privacy and protecting of your personal data is important to us. The following sections provide further details on how we process your personal information through FLOXYPAY Wallet. We don’t share your information with third parties except to deliver you our Services and products, comply with the law, make FLOXYPAY Wallet better, protect our rights, or effectuate a business transfer. We’re not a huge, faceless corporation. We’re just developers trying to deliver an incredible product. 
If you have any questions or concerns about this policy, please contact us at support@floxypay.io.
                </p>
                </section>


                <section className='policy-sec'>
                <h2>HOW DO YOU ACCEPT THIS POLICY?  </h2>
                <p>                
By using FLOXYPAY Wallet, including downloading one of our mobile applications or visiting our website, you agree to the use, 
disclosure, and procedures outlined in this Privacy Policy.
                </p>
                </section>


                <section className='policy-sec'>
                <h2>WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?  </h2>
                <p>                
                We do our best to minimize the personal information we collect from FLOXYPAY Wallet users. Your contact information, 
                such as your phone number or email address (depending on how you contact us), may be collected when you communicate with us 
                for support, if you report a bug or other error related to FLOXYPAY Wallet, or when you interact with us through social media. 
                When you use our Services, we process PUBLIC wallet addresses you generate through FLOXYPAY Wallet.
                </p>
                </section>

                <section className='policy-sec'>
                <h2>WILL WE SHARE YOUR PERSONAL DATA WITH THIRD PARTIES?  </h2>
                <p>                
                Information about our users is an important part of our business, and we are not in the business of 
                selling our users’ personal information to others. We may transfer personal data to our service 
                providers or third parties in connection with FLOXYPAY Wallet’s operation of its business, 
                as certain features on FLOXYPAY Wallet rely on various third-party products and services (collectively 
                “Third Party Services”). 
                These third-party services providers only have access to certain Personal Information, such as your 
                public Wallet addresses, to perform their functions and may not use it for other purposes. 
                Furthermore, they must 
                process the personal information in accordance with our contractual agreements and only as permitted by 
                applicable data protection laws. Business transfers: As we continue to develop our business, we might sell 
                or buy other businesses or services. In such transactions, user information generally is one of the transferred 
                business assets but remains subject to the promises made in any pre-existing Privacy Notice (unless, of course, 
                the user consents otherwise). Also, in the unlikely event that FLOXYPAY Wallet or substantially all of its 
                assets are acquired, users' information will be one of the transferred assets. We may share non-personally 
                identifiable information publicly and with our partners-like publishers, advertisers, developers, 
                or right holders.
                </p>
                </section>


                <section className='policy-sec'>
                <h2>HOW DO WE USE THE INFORMATION WE GATHER?</h2>
                <p>                
                We primarily use the limited information that we collect to enhance FLOXYPAY Wallet services. Except if we sell all or 
                a portion of our business, or as otherwise described below, we do not rent, trade, or sell your Personal Information.
                 Some ways we may use your Personal Information are to: Contact you when necessary; Respond to your comments, questions, or issues related to bugs or errors with FLOXYPAY Wallet; Provide you with additional information; Send you information and marketing materials about services and products available through FLOXYPAY Wallet, using push notifications or other means; Train our team members; Other internal business purposes. Aggregated Personal Data and Non-Personal Information We may share or disclose aggregated Personal Data or Non-Personal Information with service providers or with other persons we conduct business with, including but not limited to potential third parties, for the purpose of showcasing the performance 
                of the company.   </p>
                <p>These service providers and other persons may also share aggregated Non-Personal Information that they have independently developed or acquired with us. Additionally, we may combine aggregate information from the pixel tags and web beacons with similar data we collect from other visitors to help us improve our Services. When doing so, we do our best to ensure that aggregated information cannot be linked back to you. Agents or Third Party Partners We may provide your Personal Information to our employees, contractors, agents, service providers, and designees (“Agents”) to enable them to perform certain services for us, including improvement of website-related services and features and performance of maintenance services. 
                    Business Transfers We may choose to buy or sell assets.</p>
                    <p>In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, your Personal Information could be one of the assets transferred to or acquired by a third party. By accepting this Privacy Notice, as outlined above, you consent to any such transfer. Protection of Us and Others We will share personal information outside of FLOXYPAY Wallet if we have a reasonable belief that access, use, preservation, or disclosure of the information is reasonably necessary to comply with any applicable law, regulation, legal process, or enforceable governmental request; to cooperate with law enforcement; to enforce or apply our Terms of Use and other agreements; or to protect the rights, property, 
                    or safety of FLOXYPAY Wallet, our employees, our users, or others.</p>
                </section>


                <section className='policy-sec'>
                <h2>YOUR RIGHTS WITH RESPECT TO THE PROCESSING OF PERSONAL DATA  </h2>
                <p>     
                You are entitled (under the conditions and subject to the exceptions set out in applicable law) to: Request Access to the personal information we process about you: You can request access to the information we have collected from you. You can do this by contacting us at support@floxypay.io. We will provide you with a copy of the data we process about you. We may ask you to verify your identity to comply with your request. We will fulfill your request by sending your copy electronically. For any subsequent access request, we may charge you an administrative fee. Request a rectification of your personal data: If you believe that the information we have collected is incorrect or incomplete, you may contact us so we can update it and keep your data accurate. Object to processing your personal data: you may request that we no longer process your personal data. Request to erase your personal data: you may request the erasure of your personal data, including where such personal data would no longer be necessary 
                to achieve the purposes for which it was collected.           
                  </p>
               
                </section>

                <section className='policy-sec'>
                <h2>DATA RETENTION  </h2>
                <p>     
                Please note that even If you delete your Wallet or addresses from the FLOXYPAY Wallet mobile application, uninstall FLOXYPAY Wallet mobile applications from your device, or request that your information be deleted, we still may retain some information that you have provided to us to maintain FLOXYPAY Wallet or to comply with the laws and regulations to which FLOXYPAY Wallet is subject. If you have any question or objections as to how we collect and process your personal information, please contact support@floxypay.io.        
                  </p>
               
                </section>

                <section className='policy-sec'>
                <h2>DATA SECURITY</h2>
                <p>     
                We are committed to ensuring your information is protected per applicable laws and our data privacy policies. We have selected third-party vendors that use the Polygon network, including web3auth, that help us keep your Personal Information safe. Unfortunately, we do not control these third parties and therefore cannot guarantee complete security. We work to protect the security of your personal information during transmission by using encryption protocols and software. We maintain physical, electronic, and procedural safeguards in connection with the collection, storage, and disclosure of your personal information and secure all connections with industry-standard transport layer security. Even with all these precautions, we cannot fully guarantee against data access, disclosure, alteration, or deletion through events, including but not limited to hardware or software failure or unauthorized use. Any information you provide us is done so entirely at your own risk.
                  </p>
                </section>
                <section className='policy-sec'>
                <h2>CHILDREN</h2>
                <p>  
                We are especially sensitive about children’s information. Our Services are not targeted toward children; our users must be at least eighteen (18) years old to use our services. We don’t knowingly collect information from children under the age of 13. If you are a parent or legal guardian of a minor child, we will treat any information you provide us while using FLOXYPAY Wallet on behalf of your minor child as Personal Information as otherwise provided in this Privacy Policy. If you have questions concerning our information practices with respect to children, or if you learn that a child under the age of 13 has used FLOXYPAY Wallet, created a user account, or provided us with personal information, please email us at support@floxypay.io.   
                  </p>
                  <p>CONDITIONS OF USE, NOTICES, CHANGES, AND UPDATES TO PRIVACY NOTICE If you choose to use FLOXYPAY Wallet, your use and any dispute over privacy are subject to this Notice and our Terms of Use. If you have any concerns about privacy at FLOXYPAY Wallet, please contact us with a complete description, and we will try to resolve it. You also have the right to contact your local Data Protection Authority. We reserve the right to update and revise this Privacy Policy anytime. We occasionally review this Privacy Notice to ensure it complies with applicable laws and conforms to changes in our business. If we do revise this Privacy Notice, we will update the “Effective Date” at the top of this page so that you can tell if it has changed since your last visit, and we will do our best to notify you. Please review this Privacy Notice regularly to ensure you know its terms. Any use of FLOXYPAY Wallet after an amendment to our Privacy Notice constitutes your acceptance of the revised or amended terms.</p>
                </section>
                <section className='policy-sec'>
                <h2>QUESTIONS</h2>
                <p>
                We’d be happy to answer them. Shoot us an email or send us a note:
                </p>
                <p>Email: support@floxypay.io</p>
                <p>Mailing Address: 1312 17th Street Suite 1220, Denver, CO 80202, Unites States of America</p>
                <p>Mailing Address: 1312 17th Street Suite 1220, Denver, CO 80202, Unites States of America</p>
                <p>Thanks for reading our Privacy Policy!</p>
              
                </section>
              
          
               


            </div>           
        </div>
    )
}

export default PrivacyPolicy