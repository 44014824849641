import React from 'react';

import { BottomContactWrapper } from './BottomContactStyled';
import TopContactForm from '../TopContact/TopContactForm';

const BottomContact = () => {
  return (
    <>
    <div id="bottom">
    <BottomContactWrapper id='contactformsection'>
        <TopContactForm />
    </BottomContactWrapper>
    </div>
    </>
  )
}

export default BottomContact