import styled from "styled-components";

export const Root = styled.div`
width: 100%;
`;
export const Container = styled.div`
width: 1215px;
margin: 0 auto;
@media(max-width: 1300px){
width: 100%;

}
`;