import styled from "styled-components";

export const ClearcodeContainer = styled.div`
max-width:1350px;
width:100%;
background:#161616;
box-sizing:border-box;
padding:64px;
display:flex;
flex-direction: column;
@media (max-width:576px){
  padding:40px;
}
`;

export const ClearCodeHeading = styled.div`
font-family: "DM Sans", sans-serif;
text-align:center;
font-size:64px;
font-weight:600;
line-height:78px;
color:#fff;
@media (max-width: 576px){
  font-size: 40px;
  line-height:54px;
  }
`;
export const ClearCodeHeadingSpan = styled.span`

background: linear-gradient(45deg, #16593C -5%,  #01C26F 100%); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const ClearCodeParagraph = styled.div`
font-family: "DM Sans", sans-serif;
text-align:center;
font-size:18px;
font-weight:400;
line-height:25px;
color:#fff;
margin: 32px 0 60px 0;
@media (max-width:576px){
  margin: 30px  0;
}
`;

export const ClearCodeButton = styled.button`
font-family: "DM Sans", sans-serif;
display: block;
max-width: 288px;
width:100%;
height:75px;
text-align:center;
line-height:75px;
color: #fff;
background: rgba(255, 255, 255, 0.10);    
border-radius: 50px;
font-size: 21px;
font-weight:600;
cursor: pointer;
margin: 0 auto;
background: linear-gradient(black, black) padding-box,
linear-gradient(to right, #16593C, #01C26F) border-box;
border-radius: 50em;
border: 3px solid transparent;
`;