import "./App.css";
import { TopBar } from "./Component/LandingPage/TopBar/TopBar";
import { Footer } from "./Component/LandingPage/Footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Exchange from "./pages/Exchange/Exchange";
import { NavBar } from "./Component/LandingPage/Navigation/NavBar";
import styled from "styled-components";
import BackgroundImage from './Images/PaymentBg.png';
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { useRef, useState } from "react";

const Root = styled.div`
  background-color: #000;
  background-image: url(${BackgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
`;

function App() {
  // const contactFormRef = useRef(null);
  

  // const scrollToContactForm = () => {
  //   contactFormRef.current.scrollIntoView({ behavior: 'smooth' });
  // };
  const [ movetoBottom, setMoveToBottom ] = useState(false);
  
  return (
    <>
      <BrowserRouter>
      <TopBar />
      <NavBar setMoveToBottom={setMoveToBottom}/>
      <Routes>
        <Route path="/" element={<Home  data={movetoBottom} setMoveToBottom={setMoveToBottom}/>} />
        <Route path="paymentgateaway" element={<Exchange />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
       
      </Routes>
      <Footer />
      </BrowserRouter>        
    </>
  );
}

export default App;
