import styled from "styled-components";
import WorldMap from "./../../../Images/WorldMapBG.png";

export const Root = styled.div`
  background-color: #000;
  width: 100%;
  padding-bottom:20px;
  padding-top:50px;
 
`;
export const Container = styled.div`
width: 1215px;
margin: 0 auto;
@media(max-width: 1300px){
width: 100%;
}
`;

export const Wrapper = styled.div`
  background-image: url(${WorldMap});
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 60vh;
  background-repeat: no-repeat;
  // padding: 50px 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 70px 20px 30px 20px;  
  @media(max-width: 768px){
    padding: 0 0px 30px 0px;
}
`;
export const Heading = styled.div`
  font-size: 40px;
  font-family: "DM Sans", sans-serif;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
    @media(max-width: 576px){
      font-size: 36px;
      text-transform: capitalize;
    }
`;

export const ForeignCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  // padding: 0 20px;


  @media(max-width: 1250px){
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}
@media(max-width: 990px){
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
@media(max-width: 750px){
  grid-template-columns:  1fr 1fr;
  grid-gap: 10px;
}
@media(max-width: 440px){
  grid-template-columns:  1fr;
  grid-gap: 10px;
}
`;

export const CountryNameWrapper = styled.div`

`;
export const CountryName = styled.div`

  font-size: 30px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  color: #fff;
`;
export const CountryParagraphWrapper = styled.div`
padding: 10px 10px;

@media(max-width: 705px){
  padding: 10px 20px;
}
`;
export const CountryParagraph = styled.div`
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 300;
  color: #fff;
  padding: 0 20px;
`;

